import React, { FC, useContext, useEffect, useMemo } from 'react'
import { Box, Button, Typography, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { MapContext } from 'sav-features/map/MapContextProvider'
import Map from 'sav-features/map/Map'
import getViewportForFeature from 'sav-features/map/utils/getViewportForFeature'
import { Popup } from 'react-map-gl'
import centerOfMass from '@turf/center-of-mass'
import { IMikeTheme } from '@dhi/react-components-lab'
import { GeoJsonLayer } from 'deck.gl'

import { SidebarContext } from 'sav-features/sidebar/SidebarContextProvider'
import { DrawFeatureWithProps } from 'sav-features/training-data/types'
import { FeasyContext } from 'sav-features/feasibility-mask/FeasyContextProvider'
import { ValidationContext } from 'sav-features/validation/ValidationContextProvider'
import { TrainingDataContext } from 'sav-features/training-data/TrainingDataContextProvider'
import { RegionsContext } from 'sav-features/regions/RegionsContextProvider'
import { HabitatLayerContext } from 'sav-features/habitat-layer/HabitatLayerContextProvider'
import { PreprocessContext } from 'sav-features/preprocess/PreprocessContextProvider'
import { OrthoContext } from 'sav-features/orthophoto/OrthoContextProvider'
import LayersPanelView from './LayersPanelView'

const useStyles = makeStyles<IMikeTheme>((theme) => ({
	confirmDeleteButton: {
		backgroundColor: theme.palette.error?.main,
		color: theme.palette.background.paper,
		'&:hover': {
			backgroundColor: theme.palette.error?.dark,
		},
	},
}))

// eslint-disable-next-line radar/cognitive-complexity
const MapFeatures: FC = () => {
	const theme = useTheme()
	const {
		state: { width },
	} = useContext(SidebarContext)
	const {
		layers: { orthoLayers },
	} = useContext(OrthoContext)
	const {
		layers: { habitatLayer },
	} = useContext(HabitatLayerContext)

	const {
		state: { viewport, mapHeight, mapWidth },
		actions: { setViewport, handleViewport },
	} = useContext(MapContext)

	const {
		state: { showValidation, validationData },
	} = useContext(ValidationContext)
	const classes = useStyles()
	const {
		layers: { extentPreprocessLayer },
	} = useContext(PreprocessContext)

	const {
		state: { activeRegion },
		layers: { activeRegionLayer },
	} = useContext(RegionsContext)

	const {
		state: { draw, selectedDrawFeature },
		actions: {
			setSelectedDrawFeature,
			handleRemoveTrainingData,
			getFeatureClassColor,
		},
		layers: { trainingDrawLayer, trainingDataLayer },
	} = useContext(TrainingDataContext)

	const {
		layers: { feasyLayer },
	} = useContext(FeasyContext)
	const getCenter = (feature: DrawFeatureWithProps) => {
		const latLng = centerOfMass(feature).geometry?.coordinates

		return latLng || [0, 0]
	}

	const pointRadius = useMemo(() => {
		if (viewport.zoom !== undefined) {
			// calculate the result based on "exponential decay formula".
			// Change the "0.55" to change the curve of the exponential graph.
			return 50000 * 0.55 ** Number(viewport.zoom.toFixed(2))
		}

		return 100
	}, [viewport.zoom])
	const handleRemoveFeature = () => {
		void handleRemoveTrainingData({
			geometryId: Number(selectedDrawFeature?.properties.id),
		})
	}

	const hasSizes = useMemo(
		() => Boolean(mapHeight !== undefined && mapWidth !== undefined),
		[mapWidth, mapHeight],
	)
	const hasRegion = useMemo(() => Boolean(activeRegion), [activeRegion])

	useEffect(() => {
		if (activeRegion && mapWidth !== undefined && mapHeight !== undefined) {
			const localViewport = getViewportForFeature(
				activeRegion,
				mapHeight,
				mapWidth,
			)
			handleViewport({ ...localViewport })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasSizes, hasRegion, activeRegion, handleViewport])
	const validationDataLayer = new GeoJsonLayer({
		id: 'validation-data-layer',
		data: (showValidation && validationData) ?? undefined,
		stroked: true,
		filled: true,
		pickable: true,
		lineWidthMinPixels: 1,
		getLineWidth: 2,
		lineWidthUnits: 'pixels',
		radiusUnits: 'pixels',
		getPointRadius: pointRadius,
		getLineColor: (f: DrawFeatureWithProps) => {
			if (f.geometry.type.toLowerCase() === 'point') {
				return [242, 245, 247, 255]
			}

			return [255, 255, 255, 255]
		},
		getFillColor: (f: DrawFeatureWithProps) => {
			if (f.geometry.type.toLowerCase() === 'point') {
				return getFeatureClassColor(f)
			}
			return [0, 0, 0, 0]
		},
	})

	return (
		<>
			<LayersPanelView />
			<Map
				getCursor={() =>
					draw && draw.title !== 'Delete' ? 'crosshair' : 'pointer'
				}
				view={viewport}
				setViewport={setViewport}
				layers={[
					extentPreprocessLayer,
					...orthoLayers,
					activeRegionLayer,
					feasyLayer,
					habitatLayer,
					trainingDataLayer,
					trainingDrawLayer,
					validationDataLayer,
				]}
				style={{ minWidth: '300px' }}
				width={`calc(100vw - ${String(width)}px )`}
				controller={{
					doubleClickZoom: Boolean(!draw),
				}}
				themeType={theme.palette.type}
			>
				{selectedDrawFeature && (
					<Popup
						longitude={getCenter(selectedDrawFeature)[0]}
						latitude={getCenter(selectedDrawFeature)[1]}
						closeButton
						closeOnClick={false}
						onClose={() => {
							setSelectedDrawFeature(undefined)
						}}
					>
						<Box p={1} display="flex" flexDirection="column">
							<Box mb={1}>
								<Typography variant="h3" gutterBottom>
									Are you sure?
								</Typography>
							</Box>
							<Button
								onClick={() => handleRemoveFeature()}
								className={classes.confirmDeleteButton}
							>
								Yes
							</Button>
						</Box>
					</Popup>
				)}
			</Map>
		</>
	)
}

export default MapFeatures
