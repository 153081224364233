import React, { FC, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import VerticalResizeHandler from 'sav-features/sidebar/VerticalResizeHandler'
import { SidebarContext } from 'sav-features/sidebar/SidebarContextProvider'
import PCDateContextProvider from 'sav-features/date-selection/PCDateContextProvider'
import { RegionsContext } from 'sav-features/regions/RegionsContextProvider'
import MapFeatures from './MapFeatures'
import SidebarData from './SidebarData'

const CreateProject: FC = () => {
	const location = useLocation()

	const {
		actions: { setWidth },
		state: { width },
	} = useContext(SidebarContext)

	useEffect(() => {
		setWidth(400)
	}, [location, setWidth])

	const {
		actions: { handleGetRegions, setActiveRegion },
	} = useContext(RegionsContext)

	useEffect(() => {
		void handleGetRegions()
	}, [])

	return (
		<VerticalResizeHandler
			slotLeft={<MapFeatures />}
			slotRight={
				<PCDateContextProvider>
					<SidebarData />
				</PCDateContextProvider>
			}
			sidebarWidth={width}
			handleSidebarWidth={setWidth}
		/>
	)
}
export default CreateProject
