import React, { FC, useMemo } from 'react'
import {
	Checkbox,
	FormControl,
	Input,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
} from '@material-ui/core'
import { OrthoDataset } from 'sav-features/orthophoto/types'

interface Props {
	orthoDatasets: OrthoDataset[] | undefined
	activeOrthoDatasets: string[] | undefined
	setActiveOrthoDataset: (d: string[] | undefined) => void
	setShowOrtho: (b: boolean) => void
}

const DkOrthoControlLayer: FC<Props> = ({
	orthoDatasets,
	activeOrthoDatasets,
	setActiveOrthoDataset,
	setShowOrtho,
}) => {
	const handleChange = (newArray: string[]) => {
		setActiveOrthoDataset(newArray)
		setShowOrtho(true)
	}

	return (
		<FormControl fullWidth>
			<InputLabel id="ortho-datasets-labelInput">Select layers</InputLabel>
			<Select
				labelId="ortho-datasets-labelInput"
				id="demo-mutiple-checkbox"
				multiple
				value={activeOrthoDatasets}
				onChange={(e) => handleChange(e.target.value as string[])}
				input={<Input />}
				renderValue={(selected) => (selected as string[]).join(', ')}
				MenuProps={{
					PaperProps: {
						style: {
							marginTop: 100,
							maxHeight: 300,
						},
					},
				}}
			>
				{orthoDatasets?.map((dataset) => (
					<MenuItem key={dataset.value} value={dataset.value}>
						<Checkbox checked={activeOrthoDatasets?.includes(dataset.value)} />
						<ListItemText primary={dataset.label} />
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}

export default DkOrthoControlLayer
