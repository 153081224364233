import { AppContext } from 'sav-features/app-screen/AppContextProvider'
import { ProjectType, AddProjectPayload } from 'sav-features/app-screen/types'
import React, {
	FC,
	ReactNode,
	createContext,
	useState,
	useEffect,
	useContext,
	useCallback,
} from 'react'
import moment from 'moment'
import { useAuth0 } from '@auth0/auth0-react'
import postData from 'sav-utils/postData'
import { ColumnSort } from 'sav-features/dashboard/types'

interface DashboardContextValues {
	state: {
		columnSort: ColumnSort
		popupProjectId: number | undefined
	}
	actions: {
		setColumnSort: (c: ColumnSort) => void
		setPopupProjectId: (v: number | undefined) => void
		handleColumnSort: (s: string) => void
		handleDeleteProject: (projectId: number) => Promise<void>
		handleReloadProject: (projectId: number) => Promise<void>
	}
}

interface Props {
	children: ReactNode
}

type Context = DashboardContextValues
export const DashboardContext = createContext<Context>(
	null as unknown as Context,
)

const defaultColumnSort: ColumnSort = {
	region: false,
	project: false,
	imageDate: false,
	postProcess: false,
}

const DashboardContextProvider: FC<Props> = ({ children }) => {
	const {
		state: { allProjects },
		actions: { fetchProjects, setAllProjects, setIsLoading, addProject },
	} = useContext(AppContext)
	const { getAccessTokenSilently } = useAuth0()
	const [columnSort, setColumnSort] = useState<ColumnSort>(defaultColumnSort)
	const [popupProjectId, setPopupProjectId] = useState<number | undefined>(
		undefined,
	)

	useEffect(() => {
		const getProjectsInterval = setInterval(() => fetchProjects(), 60000)
		return () => clearInterval(getProjectsInterval)
	}, [fetchProjects])

	const handleDeleteProject = useCallback(async () => {
		try {
			setIsLoading(true)
			const body = { project_id: popupProjectId }
			await postData('/delete-project', await getAccessTokenSilently(), body)
			setPopupProjectId(undefined)
			void fetchProjects()
		} finally {
			setIsLoading(false)
		}
	}, [fetchProjects, getAccessTokenSilently, popupProjectId, setIsLoading])

	const handleReloadProject = useCallback(
		async (project_id: number) => {
			setIsLoading(true)
			const project = allProjects?.find((proj) => proj.id === project_id)

			try {
				if (project) {
					const reloadedProject: AddProjectPayload = {
						date: project.acquisition_date,
						deglint: project.preprocessing_settings.deglint,
						cloudMask: project.preprocessing_settings.apply_cloud_mask,
						regionId: project.region_geometry.properties.id,
					}

					await postData('/delete-project', await getAccessTokenSilently(), {
						project_id,
					})

					await addProject({
						...reloadedProject,
					})

					void fetchProjects()
				}
			} finally {
				setIsLoading(false)
			}
		},
		[
			addProject,
			allProjects,
			fetchProjects,
			getAccessTokenSilently,
			setIsLoading,
		],
	)

	const handleColumnSort = useCallback(
		// eslint-disable-next-line radar/cognitive-complexity
		(columnName: string) => {
			let sortVals: ProjectType[] = []

			const handleSortColumn = (cName: string) => {
				setColumnSort((s) => ({
					...s,
					[cName]: !s[cName],
				}))
			}

			if (allProjects) {
				switch (columnName) {
					case 'region': {
						// region
						sortVals = allProjects.sort((a: ProjectType, b: ProjectType) => {
							if (columnSort.region === false) {
								return (
									a.region_geometry.properties.id -
									b.region_geometry.properties.id
								)
							}
							return (
								b.region_geometry.properties.id -
								a.region_geometry.properties.id
							)
						})
						handleSortColumn(columnName)
						break
					}
					case 'project': {
						// project
						sortVals = allProjects.sort((a: ProjectType, b: ProjectType) =>
							columnSort.project === false ? a.id - b.id : b.id - a.id,
						)
						handleSortColumn(columnName)
						break
					}
					case 'imageDate': {
						// image date
						sortVals = allProjects.sort((a: ProjectType, b: ProjectType) => {
							if (columnSort.imageDate === false) {
								return moment(a.acquisition_date).format() <
									moment(b.acquisition_date).format()
									? -1
									: 1
							}
							return moment(a.acquisition_date).format() >
								moment(b.acquisition_date).format()
								? -1
								: 1
						})
						handleSortColumn(columnName)
						break
					}
					case 'postProcess': {
						// postProcessing status
						sortVals = allProjects.sort((a: ProjectType, b: ProjectType) => {
							const aVal: any = a.last_product ? a.last_product.status : null
							const bVal: any = b.last_product ? b.last_product.status : null
							if (columnSort.postProcess === false) {
								if (bVal === null) {
									return aVal === null ? 0 : -1
								}
								if (aVal === null) {
									return 1
								}
								if (aVal < bVal) {
									return -1
								}
								return 1
							}
							if (bVal === null) {
								return aVal === null ? 0 : 1
							}

							if (aVal === null) {
								return -1
							}
							if (aVal < bVal) {
								return 1
							}
							return -1
						})
						handleSortColumn(columnName)
						break
					}
					default: {
						sortVals = allProjects
					}
				}
				setAllProjects([...sortVals])
			}
		},
		[
			allProjects,
			columnSort.imageDate,
			columnSort.postProcess,
			columnSort.project,
			columnSort.region,
			setAllProjects,
		],
	)

	useEffect(() => {
		void fetchProjects()
	}, [fetchProjects])

	return (
		<DashboardContext.Provider
			value={{
				state: {
					columnSort,
					popupProjectId,
				},
				actions: {
					setColumnSort,
					handleColumnSort,
					setPopupProjectId,
					handleDeleteProject,
					handleReloadProject,
				},
			}}
		>
			{children}
		</DashboardContext.Provider>
	)
}
export default DashboardContextProvider
