import React from 'react'
import ReactDOM from 'react-dom'
import 'typeface-roboto'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import App from './App'

// if (process.env.NODE_ENV !== 'development') {
// 	Sentry.init({
// 		dsn: 'https://448c61fb525b459b865b55d591b0e4c3@o377546.ingest.sentry.io/5521552',
// 		integrations: [new Integrations.BrowserTracing()],
// 		tracesSampleRate: 1.0,
// 	})
// }

ReactDOM.render(<App />, document.getElementById('root'))
