import { BitmapLayer, TileLayer } from 'deck.gl'
import React, {
	FC,
	ReactNode,
	createContext,
	useState,
	useEffect,
	useContext,
	useMemo,
} from 'react'
import { OrthoContext } from '../orthophoto/OrthoContextProvider'
import { OrthoDataset } from '../orthophoto/types'

interface DkOrthoContextValues {
	state: {
		activeOrthoDatasets: string[] | undefined
		dkOrthoDatasets: OrthoDataset[] | undefined
	}
	actions: {
		setActiveOrthoDatasets: (d: string[] | undefined) => void
	}
}

interface Props {
	children: ReactNode
}

type Context = DkOrthoContextValues
export const DkOrthoContext = createContext<Context>(null as unknown as Context)
const orthoToken = String(process.env.REACT_APP_ORTHOPHOTO_TOKEN)
const DkOrthoContextProvider: FC<Props> = ({ children }) => {
	const {
		state: { showOrtho },
		layers: { setOrthoLayers },
	} = useContext(OrthoContext)

	const [activeOrthoDatasets, setActiveOrthoDatasets] = useState<
		string[] | undefined
	>(['orto_foraar'])

	const [dkOrthoDatasets, setDkOrthoDatasets] = useState<
		OrthoDataset[] | undefined
	>(undefined)

	const getAllLayers = async () => {
		try {
			const resp = await fetch(
				`https://api.dataforsyningen.dk/service?request=GetCapabilities&servicename=orto_foraar_DAF&service=WMS&version=1.3.0&token=${orthoToken}`,
			).then((res) => res.text())

			const xmlParser = new DOMParser().parseFromString(resp, 'text/xml')
			const layers = xmlParser.querySelectorAll('Layer > Layer > Name')
			const matches = Array.from(layers)
				.map((layer) => layer.textContent)
				.filter(
					(match) => !(match?.includes('index') || match?.includes('cir')),
				)
			const orthoDatasetsMapped = matches.map((item: string) => ({
				label: item,
				value: item,
			}))
			setDkOrthoDatasets(orthoDatasetsMapped)
		} catch (err) {
			console.error('Error', err)
		}
	}

	const getOrthoLayer = (layerName: string) =>
		new TileLayer({
			id: `ortho-dataset-${layerName}`,
			minZoom: 0,
			pickable: true,
			maxZoom: 19,
			tileSize: 256,
			getTileData: ({ bbox: { west, south, east, north } }): Promise<any> => {
				const bbox = `${String(south)},${String(west)},${String(
					north,
				)},${String(east)}`
				const url = `https://api.dataforsyningen.dk/service
					?request=GetMap
					&width=256
					&height=256
					&crs=EPSG:4326
					&bbox=${bbox}
					&format=image/png
					&layers=${layerName}
					&servicename=orto_foraar_DAF
					&service=WMS
					&version=1.3.0
					&ignoreillegallayers=TRUE
					&transparent=TRUE
					&token=${orthoToken}`

				const imagePromise = fetch(url, {
					headers: {
						Accept: 'image/png',
					},
				})

				return imagePromise
					.then((res) => res.blob())
					.then((blobRes) => createImageBitmap(blobRes))
					.then((res) => res)
			},
			renderSubLayers: (props) => {
				const {
					bbox: { west, south, east, north },
				} = props.tile

				return new BitmapLayer(props, {
					data: null,
					image: props.data,
					bounds: [west, south, east, north],
				})
			},
		})

	const orthoLayer = useMemo(
		() =>
			showOrtho && activeOrthoDatasets
				? activeOrthoDatasets.map((item) => getOrthoLayer(item))
				: [undefined],
		[activeOrthoDatasets, showOrtho],
	)

	useEffect(() => {
		setOrthoLayers(orthoLayer)
	}, [orthoLayer, setOrthoLayers])

	useEffect(() => {
		void getAllLayers()
	}, [])
	return (
		<DkOrthoContext.Provider
			value={{
				state: {
					activeOrthoDatasets,
					dkOrthoDatasets,
				},
				actions: {
					setActiveOrthoDatasets,
				},
			}}
		>
			{children}
		</DkOrthoContext.Provider>
	)
}
export default DkOrthoContextProvider
