import React, { FC, useContext, useState, useEffect } from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { AppContext } from 'sav-features/app-screen/AppContextProvider'
import { IMikeTheme } from '@dhi/react-components-lab'

import Description from 'sav-features/dashboard/Description'
import TableHeader from 'sav-features/dashboard/TableHeader'
import TableContent from 'sav-features/dashboard/TableContent'
import DeletePopup from 'sav-features/dashboard/DeletePopup'
import DashboardHeader from 'sav-features/dashboard/DashboardHeader'
import { HeaderItems } from 'sav-features/dashboard/types'
import { DashboardContext } from './DashboardContextProvider'

const useStyles = makeStyles<IMikeTheme>((theme) => ({
	background: {
		backgroundImage: `url('https://grasdatastorage.blob.core.windows.net/images/sav-2020-dash-bg.jpg')`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundAttachment: 'fixed',
		minHeight: '100vh',
	},
	whiteOverlay: {
		backgroundColor:
			theme.palette.type === 'dark'
				? 'rgba(18, 18, 18 ,0.7)'
				: 'rgba(255,255,255,0.7)',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundAttachment: 'fixed',
		minHeight: '100vh',
	},
	wrapper: {
		boxShadow: theme.shadows[6],
		borderRadius: 6,
	},
	bold: {
		fontWeight: 600,
	},
	moreButton: {
		fontWeight: 500,
		color: theme.palette.secondary.main, // Automatically generated secondary color
		cursor: 'pointer',
		'&:hover': {
			color: theme.palette.secondary.dark,
		},
	},
}))

const headerItems: HeaderItems = {
	region: {
		label: 'Region',
		gridSize: 2,
	},
	project: {
		label: 'Project',
		gridSize: 2,
	},
	imageDate: {
		label: 'Satellite image date',
		gridSize: 2,
	},
	// 'Product',
	postProcess: {
		label: 'Processing state',
		gridSize: 2,
	},
	actions: {
		label: 'Actions',
		gridSize: 4,
	},
}

const Dashboard: FC = () => {
	const {
		actions: { fetchProjects },
		state: { allProjects, isLoading },
	} = useContext(AppContext)

	const {
		actions: { setPopupProjectId, handleDeleteProject, handleColumnSort },
		state: { popupProjectId },
	} = useContext(DashboardContext)

	const [showDescDetails, setShowDescDetails] = useState(false)

	const classes = useStyles()

	const history = useHistory()

	const handlePopupDeleteProject = (projectId: number) => {
		setPopupProjectId(projectId)
	}

	const viewProject = (id: number) => {
		history.push(`/project/${id}`)
	}

	useEffect(() => {
		const getProjectsInterval = setInterval(() => fetchProjects(), 60000)
		return () => clearInterval(getProjectsInterval)
	}, [fetchProjects])

	return (
		<Box className={classes.background}>
			<Box className={classes.whiteOverlay}>
				<Box width={1} py={12} px="10%" maxHeight="98vh" overflow="auto">
					<Box mb={2}>
						<Description
							showExtraContent={showDescDetails}
							content={
								<>
									<Typography
										className={classes.bold}
										color="primary"
										variant="body1"
									>
										Welcome
									</Typography>
									<Typography color="primary" variant="body2" gutterBottom>
										This application facilitates satellite-based mapping of land
										and wetland cover and extent at 10x10 m pixel resolution in
										the Danish National Parks. The application provides access
										to Copernicus Sentinel-2 satellite data from 2018 onwards
										and tools to map Forest, Grass, Built-up, Water and up to
										eight additional, freely selectable classes to represent
										local land cover classes.
									</Typography>
									<Typography color="primary" variant="body2" gutterBottom>
										Start by creating a new project or open an existing project
										in the list below. The application will guide you through
										all the steps needed, starting with region and image
										selection, to the final classification.
										<span
											tabIndex={-2}
											role="button"
											className={classes.moreButton}
											onClick={() => setShowDescDetails(!showDescDetails)}
											onKeyDown={(e) =>
												e.key === 'Tab' && setShowDescDetails(!showDescDetails)
											}
										>
											{!showDescDetails ? 'Read more' : 'Read less'}
										</span>
									</Typography>
								</>
							}
							extraContent={
								<>
									<Typography color="primary" variant="body2" gutterBottom>
										Note that the applied method is only effective for
										subaquatic mapping, e.g. subaquatic vegetation, when the
										seafloor is visible in the satellite image, which is roughly
										down to 10 meters depth depending on local conditions. Where
										turbidity obscures visibility and hence the seafloor or the
										water column is simply too deep to see the seafloor,
										classifications are not feasible with satellite images.
									</Typography>
									<Typography color="primary" variant="body2">
										Some classes might be very similar and difficult for the
										tool to map. Therefore, accurate and evenly distributed
										training polygons are important when running or
										re-processing the land cover classification.
									</Typography>
								</>
							}
						/>
					</Box>
					<Box mb={2} className={classes.wrapper}>
						<DashboardHeader onFetchProjects={() => fetchProjects()} />
						<TableHeader
							onColumnSort={handleColumnSort}
							headerItems={headerItems}
						/>
						{allProjects && (
							<TableContent
								deleteProject={handlePopupDeleteProject}
								projects={allProjects}
								onViewProject={(id: number) => viewProject(id)}
								isAppLoading={isLoading}
								hasPreProcessing={false}
							/>
						)}
						{popupProjectId && (
							<DeletePopup
								handleClose={() => setPopupProjectId(undefined)}
								handleDelete={() => void handleDeleteProject(popupProjectId)}
								popupContent={String(popupProjectId)}
							/>
						)}
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export default Dashboard
