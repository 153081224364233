import React, { FC, useEffect, useContext } from 'react'
import RegionsContextProvider, {
	RegionsContext,
} from 'sav-features/regions/RegionsContextProvider'
import MapContextProvider from 'sav-features/map/MapContextProvider'
import CreateProject from './CreateProject'

const CreateProjectScreen: FC = () => (
	<RegionsContextProvider isExtent>
		<MapContextProvider>
			<CreateProject />
		</MapContextProvider>
	</RegionsContextProvider>
)

export default CreateProjectScreen
