import React, {
	FC,
	useState,
	useCallback,
	ReactNode,
	createContext,
	useContext,
} from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useSnackbar } from 'notistack'
import getData from 'sav-utils/getData'
import bbox from '@turf/bbox'
import { AppContext } from '../app-screen/AppContextProvider'
import { PCDateWithClouds, GetDatesPayload, PCStacItemsRes } from './types'
import { RegionsContext } from '../regions/RegionsContextProvider'

interface DateContextValues {
	state: {
		dates: PCDateWithClouds[] | undefined
		activeDate: string | undefined
		isInvalidDate: boolean
		datePreviewImage: string[] | undefined
		isDatePreviewLoading: boolean
	}
	actions: {
		setDates: (r: PCDateWithClouds[] | undefined) => void
		setActiveDate: (r: string | undefined) => void
		handleGetDates: (pay: GetDatesPayload) => void
		setIsInvalidDate: (r: boolean) => void
		setDatePreviewImage: (v: string[] | undefined) => void
		setIsDatePreviewLoading: (v: boolean) => void
		handleGetPreview: (d: string) => void
	}
}

interface Props {
	children: ReactNode
}

type Context = DateContextValues
export const PCDateContext = createContext<Context>(null as unknown as Context)

const DateContextProvider: FC<Props> = ({ children }) => {
	const { getAccessTokenSilently } = useAuth0()
	const { enqueueSnackbar } = useSnackbar()

	const {
		actions: { setIsLoading },
	} = useContext(AppContext)
	const {
		state: { activeRegion },
	} = useContext(RegionsContext)

	const [dates, setDates] = useState<PCDateWithClouds[] | undefined>(undefined)
	const [activeDate, setActiveDate] = useState<string | undefined>(undefined)
	const [isInvalidDate, setIsInvalidDate] = useState<boolean>(false)
	const [datePreviewImage, setDatePreviewImage] = useState<
		string[] | undefined
	>(undefined)
	const [isDatePreviewLoading, setIsDatePreviewLoading] =
		useState<boolean>(false)

	const handleGetDates = useCallback(async () => {
		setIsLoading(true)
		try {
			const bboxOfRegion = bbox(activeRegion)
			const arrayOfYears = [
				[2015, 2016],
				[2016, 2017],
				[2017, 2018],
				[2018, 2019],
				[2019, 2020],
				[2020, 2021],
				[2021, 2022],
				[2022, 2023],
				[2023, 2024],
			]
			const arrayOfPromises = arrayOfYears.map((year) =>
				fetch(
					`https://planetarycomputer.microsoft.com/api/stac/v1/search?
						collections=sentinel-2-l2a
						&bbox=${String(bboxOfRegion)}
						&datetime=${year[0]}-01-01/${year[1]}-01-01
						&limit=1000`,
				)
					.then((yearCall) => yearCall.json())
					.then((res) => res.features),
			)
			const resJson: PCStacItemsRes[] = await Promise.all(arrayOfPromises)
			const pcDates = resJson.flat()

			const mapPreviewsOnDates: {
				[key: string]: {
					date: string
					cloud_coverage: number[]
					previews: string[]
				}
			} = {}
			const mapDatesForData = pcDates.map(({ properties, assets }) => ({
				date: properties.datetime.substr(0, 10),
				cloud_coverage: properties['eo:cloud_cover'],
				preview: assets.rendered_preview.href,
			}))

			mapDatesForData.forEach((elem) => {
				if (mapPreviewsOnDates[elem.date]) {
					mapPreviewsOnDates[elem.date].cloud_coverage.push(elem.cloud_coverage)
					mapPreviewsOnDates[elem.date].previews.push(elem.preview)
				} else {
					mapPreviewsOnDates[elem.date] = {
						date: elem.date,
						cloud_coverage: [elem.cloud_coverage],
						previews: [elem.preview],
					}
				}
			})

			const dateWithCloudsAndPreviews: PCDateWithClouds[] = Object.keys(
				mapPreviewsOnDates,
			).map((key) => {
				let sum = 0
				for (
					let i = 0;
					i < mapPreviewsOnDates[key].cloud_coverage.length;
					i += 1
				) {
					sum += mapPreviewsOnDates[key].cloud_coverage[i]
				}

				return {
					date: key,
					cloud_coverage: sum / mapPreviewsOnDates[key].cloud_coverage.length,
					previews: mapPreviewsOnDates[key].previews,
				}
			})

			setDates(dateWithCloudsAndPreviews)
		} catch (err) {
			enqueueSnackbar(`There was a problem when retrieving the dates.`, {
				variant: 'error',
				autoHideDuration: 8000,
			})
		} finally {
			setIsLoading(false)
		}
	}, [setIsLoading, activeRegion, enqueueSnackbar])

	const handleGetPreview = useCallback(
		(date: string) => {
			try {
				setIsDatePreviewLoading(true)
				const datePreviewImages = dates?.find(
					(dateItem) => dateItem.date === date,
				)
				setIsInvalidDate(false)

				setDatePreviewImage(datePreviewImages?.previews)
			} catch (err) {
				enqueueSnackbar(
					`There is a Sentinel-2 tile archived for this date but it contains no data for this region.`,
					{
						variant: 'error',
						autoHideDuration: 8000,
					},
				)
				setDatePreviewImage(undefined)
				setIsInvalidDate(true)
			} finally {
				setIsDatePreviewLoading(false)
			}
		},
		[dates, enqueueSnackbar],
	)

	return (
		<PCDateContext.Provider
			value={{
				state: {
					dates,
					activeDate,
					isInvalidDate,
					datePreviewImage,
					isDatePreviewLoading,
				},
				actions: {
					setDates,
					setActiveDate,
					handleGetDates,
					setIsInvalidDate,
					setDatePreviewImage,
					setIsDatePreviewLoading,
					handleGetPreview,
				},
			}}
		>
			{children}
		</PCDateContext.Provider>
	)
}
export default DateContextProvider
