import React, { FC, useContext, useMemo } from 'react'
import { Box, Button, Tooltip, Typography } from '@material-ui/core'
import { GetAppRounded } from '@material-ui/icons'
import Sidebar from 'sav-features/sidebar/Sidebar'
import SidebarTitle from 'sav-features/sidebar/SidebarTitle'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { IMikeTheme } from '@dhi/react-components-lab'
import { SidebarContext } from 'sav-features/sidebar/SidebarContextProvider'
import { DataProductType } from 'sav-features/view-project/types'
import { ViewProjectContext } from 'sav-features/view-project/ViewProjectContextProvider'
import { RegionsContext } from 'sav-features/regions/RegionsContextProvider'
import { ProductContext } from 'sav-features/product/ProductContextProvider'
import TrainingData from 'sav-features/training-data/SidebarTrainingData'
import PostProcessingSettings from 'sav-features/product/SidebarPostProcessing'

const useStyles = makeStyles<IMikeTheme>((theme) => ({
	itemWrapper: {
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
}))

const trainingUploadOptions = [
	{
		id: 'epsg:4326' as const,
		label: 'WGS84',
	},
]

const sidebarItems = [
	{ title: 'Training data', comp: TrainingData, id: 1 },
	{ title: 'Post-processing', comp: PostProcessingSettings, id: 2 },
]

const SidebarData: FC = () => {
	const {
		state: { currentProject },
	} = useContext(ViewProjectContext)
	const {
		actions: { handleAddProduct, handleDownloadProduct },
	} = useContext(ProductContext)
	const {
		state: { activeRegion },
	} = useContext(RegionsContext)
	const {
		state: { width },
	} = useContext(SidebarContext)
	const classes = useStyles()
	const formatedDate =
		(currentProject?.acquisition_date &&
			moment(currentProject?.acquisition_date).format('DD-MM-YYYY')) ||
		''

	const currentProduct = useMemo(
		() => currentProject?.last_product,
		[currentProject],
	)
	return (
		<Sidebar sidebarWidth={width}>
			<Box height="calc(100% - 124px)">
				<Box py={1} px={2}>
					<SidebarTitle
						title={activeRegion?.properties.swedishName || ''}
						subTitle={[formatedDate]}
					/>
				</Box>
				<Box
					height="calc(100% - 60px)"
					display="flex"
					flexDirection="column"
					justifyContent="space-between"
				>
					<Box>
						{sidebarItems.map(
							({ title, comp: Component, id }: DataProductType, i: number) => (
								<Box
									key={`card-item-${title}-${id}`}
									px={2}
									py={2}
									className={classes.itemWrapper}
								>
									<Box mb={2}>
										<Typography variant="h5">{title}</Typography>
									</Box>
									<Component index={i} uploadOptions={trainingUploadOptions} />
								</Box>
							),
						)}
					</Box>
					<Box p={2}>
						<Button variant="contained" onClick={handleAddProduct} fullWidth>
							Run model
						</Button>
						<Box mt={1}>
							<Tooltip
								color="primary"
								title="Note that products created using this portal are subject to georeferencing inaccuracies inherent in Sentinel-2 data"
								open={currentProduct ? undefined : false}
							>
								<Box width={1} display="flex" justifyContent="space-between">
									<Box mr={0.5} width={1}>
										<Button
											disabled={currentProduct?.status !== 'COMPLETED'}
											fullWidth
											variant="outlined"
											color="secondary"
											onClick={() => handleDownloadProduct()}
											startIcon={<GetAppRounded />}
										>
											UTM
										</Button>
									</Box>
									<Box ml={0.5} width={1}>
										<Button
											disabled={!currentProduct}
											fullWidth
											variant="outlined"
											color="secondary"
											onClick={() => handleDownloadProduct('epsg:3006')}
											startIcon={<GetAppRounded />}
										>
											SWEREF99 TM
										</Button>
									</Box>
								</Box>
							</Tooltip>
						</Box>
					</Box>
				</Box>
			</Box>
		</Sidebar>
	)
}

export default SidebarData
