import React, {
	FC,
	ReactNode,
	createContext,
	useState,
	useCallback,
} from 'react'
import { TileLayer } from '@deck.gl/geo-layers'
import { TileLayerProps } from '@deck.gl/geo-layers/tile-layer/tile-layer'
import { ProjectType } from '../app-screen/types'
import useRasterLayer from '../map/hooks/useRasterLayer'

interface FeasyContextValues {
	state: {
		feasyOpacity: number
		infeasyOpacity: number
		showFeasy: boolean
		showInfeasy: boolean
	}
	actions: {
		setFeasyOpacity: (o: number) => void
		setInfeasyOpacity: (o: number) => void
		setShowFeasy: (s: boolean) => void
		setShowInfeasy: (s: boolean) => void
	}
	layers: {
		infeasyLayer: TileLayer<unknown, TileLayerProps<unknown>> | undefined
		feasyLayer: TileLayer<unknown, TileLayerProps<unknown>> | undefined
	}
}

const TERRACOTTA_URL = process.env.REACT_APP_TERACCOTTA_URL

interface Props {
	children: ReactNode
	currentProject: ProjectType | undefined
}

type Context = FeasyContextValues
export const FeasyContext = createContext<Context>(null as unknown as Context)
// Feasy stands for Feasibility
const FeasyContextProvider: FC<Props> = ({ children, currentProject }) => {
	const [infeasyOpacity, setInfeasyOpacity] = useState(100)
	const [feasyOpacity, setFeasyOpacity] = useState(100)
	const [showFeasy, setShowFeasy] = useState(false)
	const [showInfeasy, setShowInfeasy] = useState(false)

	const useFeasibilityMask = useCallback(() => {
		if (currentProject && showFeasy) {
			const regionId = String(currentProject.region_geometry.properties.id)

			const colors = { 1: '337AAE', 2: '33AE9F', 6: 'C5F8F9' }

			return `${String(TERRACOTTA_URL)}/singleband/${String(
				currentProject.project_deployment,
			)}/feasibility/null/null/${regionId}/B01/{z}/{x}/{y}.png?colormap=explicit&explicit_color_map=${encodeURIComponent(
				JSON.stringify(colors),
			)}`
		}

		return undefined
	}, [currentProject, showFeasy])

	const useInfeasibilityMask = useCallback(() => {
		if (currentProject && showInfeasy) {
			const regionId = String(currentProject.region_geometry.properties.id)

			const colors = { 3: '00062d', 5: '084919' }

			return `${String(TERRACOTTA_URL)}/singleband/${String(
				currentProject.project_deployment,
			)}/feasibility/null/null/${regionId}/B01/{z}/{x}/{y}.png?colormap=explicit&explicit_color_map=${encodeURIComponent(
				JSON.stringify(colors),
			)}`
		}

		return undefined
	}, [currentProject, showInfeasy])

	const infeasyLayer = useRasterLayer({
		id: 'infeasibility-mask-layer',
		data: useInfeasibilityMask(),
		opacity: infeasyOpacity / 100,
	})
	const feasyLayer = useRasterLayer({
		id: 'feasibility-mask-layer',
		data: useFeasibilityMask(),
		opacity: feasyOpacity / 100,
	})

	return (
		<FeasyContext.Provider
			value={{
				state: {
					feasyOpacity,
					showFeasy,
					infeasyOpacity,
					showInfeasy,
				},
				actions: {
					setFeasyOpacity,
					setShowFeasy,
					setInfeasyOpacity,
					setShowInfeasy,
				},
				layers: {
					infeasyLayer,
					feasyLayer,
				},
			}}
		>
			{children}
		</FeasyContext.Provider>
	)
}
export default FeasyContextProvider
