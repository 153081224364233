import React, { FC, useContext, useMemo } from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PreprocessControl from 'sav-features/preprocess/PreprocessControl'
import HabitatLayerControl from 'sav-features/habitat-layer/HabitatLayerControl'
import FeasyControl from 'sav-features/feasibility-mask/FeasyControl'
import Validation from 'sav-features/validation/Validation'
import { PreprocessContext } from 'sav-features/preprocess/PreprocessContextProvider'
import { ViewProjectContext } from 'sav-features/view-project/ViewProjectContextProvider'
import LayersPanelControl from 'sav-features/layers-panel/LayersPanelControl'
import OrthoControl from 'sav-features/orthophoto/OrthoLayerControl'
import { DkOrthoContext } from 'sav-features/dk-orthophoto/DkOrthoContextProvider'
import { OrthoContext } from 'sav-features/orthophoto/OrthoContextProvider'
import DkOrthoControlLayers from 'sav-features/dk-orthophoto/DkOrthoControlLayers'

const useStyles = makeStyles((theme) => ({
	containerHeader: {
		borderBottom: `1px solid ${theme.palette.divider}`,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: 15,
	},
	icon: {
		width: 18,
		height: 18,
		color: theme.palette.primary.main,
	},
	containerOptions: {
		padding: 15,
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
}))

const MapLayers: FC = () => {
	const {
		state: { currentProject },
	} = useContext(ViewProjectContext)

	const {
		state: { preProcessRanges },
	} = useContext(PreprocessContext)

	const {
		state: { activeOrthoDatasets, dkOrthoDatasets },
		actions: { setActiveOrthoDatasets },
	} = useContext(DkOrthoContext)
	const {
		actions: { setShowOrtho },
	} = useContext(OrthoContext)

	const classes = useStyles()

	const currentProduct = useMemo(
		() => currentProject?.last_product,
		[currentProject],
	)

	return (
		<LayersPanelControl>
			{preProcessRanges && (
				<Box>
					<PreprocessControl isExtent />
				</Box>
			)}
			<Box pt={1} className={classes.containerOptions}>
				<OrthoControl />
				<Box width={1} mt={0.5}>
					<DkOrthoControlLayers
						activeOrthoDatasets={activeOrthoDatasets}
						orthoDatasets={dkOrthoDatasets}
						setActiveOrthoDataset={setActiveOrthoDatasets}
						setShowOrtho={setShowOrtho}
					/>
				</Box>
			</Box>
			<Box py={1} className={classes.containerOptions}>
				{currentProduct?.status === 'COMPLETED' ? (
					<HabitatLayerControl currentProduct={currentProduct} />
				) : (
					<Typography variant="body2">
						No habitat classification available.
					</Typography>
				)}
			</Box>
		</LayersPanelControl>
	)
}

export default MapLayers
